<template>
  <div class="dispatcher-report-cards">
    <WithReports :filters="filters" :items-per-page="1000">
      <div slot-scope="{ reports, areReportsLoading }">
        <VLayout wrap align-center>
          <VFlex v-if="areReportsLoading" sm12>
            <BaseSpinner message="Loading Reports..." />
          </VFlex>
          <VFlex
            v-for="report in filteredReports(reports)"
            v-else-if="filteredReports(reports).length"
            :key="report.ID"
            sm6
            md4
            lg3
          >
            <VContainer pa-3>
              <VLayout justify-space-between column>
                <DispatcherReportCard :report="report" />
              </VLayout>
            </VContainer>
          </VFlex>
          <VFlex v-else sm12>
            <h2 class="pa-5">No editable reports found on this day.</h2>
          </VFlex>
        </VLayout>
      </div>
    </WithReports>
  </div>
</template>

<script>
import { HumanFields, ReportStatuses } from '@constants/knack'
import { mapGetters } from 'vuex'

import dayjs from '@utils/dayjsCustomParse'
import fuzzyMatch from '@utils/fuzzyMatch'
import _sortBy from 'lodash/sortBy'

import WithReports from '@dataProviders/WithReports'
import DispatcherReportCard from '@dispatcher/DispatcherReportCard'

export default {
  name: 'DispatcherReportsLists',
  components: {
    WithReports,
    DispatcherReportCard,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getReportSearchTerm', 'getForemanFilter']),
    filters() {
      return [
        {
          field: HumanFields.DAILY_REPORT.REPORT_DATE,
          operator: 'is',
          value: dayjs(this.$route.params.date, 'MM-DD-YYYY').format(
            'MM/DD/YYYY'
          ),
        },
        {
          field: HumanFields.DAILY_REPORT.REPORT_STATUS,
          operator: 'is not',
          value: ReportStatuses.LOCKED,
        },
      ]
    },
  },
  methods: {
    filteredReports(reports) {
      let filteredReports = reports
      if (this.getReportSearchTerm) {
        filteredReports = fuzzyMatch(
          filteredReports,
          'DAILY_REPORT_NAME',
          this.getReportSearchTerm
        )
      }
      if (this.getForemanFilter) {
        filteredReports = fuzzyMatch(
          filteredReports,
          'FOREMANS_NAME',
          this.getForemanFilter
        )
      }
      return _sortBy(filteredReports, 'DAILY_REPORT_NAME')
    }, // filteredReports
  },
}
</script>
