<template>
  <VDialog value="show" :max-width="500" persistent>
    <VCard class="text-xs-left">
      <VCardTitle class="orange accent-2 white--text">
        <VFlex xs12><h2>Warning</h2></VFlex>
        <VFlex xs12 mb-2 class="font-weight-bold"
          >Asset Already Assigned!</VFlex
        >
      </VCardTitle>
      <VCardText class="font-weight-medium pa-3">
        <h2>{{ assetName }}</h2>
        <p>is already assigned to these reports:</p>
        <ul>
          <li v-for="assgnt of getMultiAssgnObj.assgnts" :key="assgnt.ID">{{
            getReportById(assgnt.DAILY_REPORT[0].id).DAILY_REPORT_NAME
          }}</li>
        </ul>
        <h2 class="py-2"
          >Assign to
          {{
            getMultiAssgnObj.targetReport
              ? `"${getMultiAssgnObj.targetReport.DAILY_REPORT_NAME}"`
              : 'this report'
          }}?</h2
        >
        <p class="py-2">Please use caution when multi-assigning assets!</p>
        <div class="text-xs-right">
          <BaseButton
            color="orange"
            depressed
            @click="$emit('confirmAssignment')"
            >Assign</BaseButton
          >
          <BaseButton color="grey" depressed @click="$emit('hide')"
            >Cancel</BaseButton
          >
        </div>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import { mapGetters } from 'vuex'
import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'AssetAlreadyAssignedModal',
  components: {
    VDialog,
    VCardText,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getMultiAssgnObj', 'getReportById']),
    assetName() {
      return this.$route.params.assetType === 'laborer'
        ? this.getMultiAssgnObj.asset.LABORER_NAME
        : this.getMultiAssgnObj.asset.EQUIPMENT_NAME
    }, // assetName
  },
}
</script>
