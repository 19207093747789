<template>
  <div class="dispatcher-reports-list-header">
    <VContainer fluid px-3 py-2 grid-list-xl
      ><VLayout row wrap>
        <VFlex xs12 :sm1="!isSearchFullWidth" :sm3="isSearchFullWidth" pb-0>
          <BaseSearch
            :outline="false"
            color="white"
            :placeholder="isSearchFullWidth ? 'Filter by Search' : ''"
            hide-details
            @searchTermUpdate="updateReportSearchTerm"
            @clear="clearReportSearchTerm"
            @focus="isSearchFocused = true"
            @blur="isSearchFocused = false"
          />
        </VFlex>
        <VFlex xs6 sm2 pb-0>
          <WithFilters>
            <div
              slot-scope="{
                areAnyFiltersOn,
                clearFilters,
                foremen,
                areForemenLoading,
              }"
            >
              <BaseSpinner
                v-if="areForemenLoading"
                margin="7"
                message="Loading Foremen..."
                size="small"
              />
              <BaseDropdown
                v-else
                background-color="blue"
                dark
                title="Foreman"
                :options="foremen"
                hide-details
                clearable
                :height="['sm', 'xs'].includes($mq) ? '20px' : 'auto'"
                @selected="updateForemanFilter"
              />
            </div>
          </WithFilters>
        </VFlex>
        <VFlex xs6 sm5 text-xs-left pb-0>
          <BaseButton
            color="blue"
            md-icon="keyboard_arrow_left"
            md-icon-position="left"
            :small="['sm', 'xs'].includes($mq)"
            :no-icon-padding="showCondensedControls"
            @click="previousDay"
            >{{ !showCondensedControls ? 'Prev Day' : '' }}</BaseButton
          >
          <BaseButton
            color="blue"
            :small="['sm', 'xs'].includes($mq)"
            @click="gotoToday"
            >{{ !showCondensedControls ? 'Today' : '2DAY' }}</BaseButton
          >
          <BaseButton
            color="blue"
            md-icon="keyboard_arrow_right"
            md-icon-position="right"
            :no-icon-padding="showCondensedControls"
            :small="['sm', 'xs'].includes($mq)"
            @click="nextDay"
            >{{ !showCondensedControls ? 'Next Day' : '' }}</BaseButton
          >
        </VFlex>
        <VFlex pb-0 text-xs-right grow>
          <template
            v-if="numSelectedAssetsAndAssgnts && !['sm', 'xs'].includes($mq)"
          >
            <span>{{ numSelectedAssetsAndAssgnts }} Selected</span>
            <BaseButton
              color="blue"
              md-icon="clear"
              icon
              small
              no-icon-padding
              title="Deselect All"
              @click="deselectAllAssgntsAndAssets"
            />
          </template>
          <BaseButton
            v-if="$route.params.assetType === 'laborer'"
            color="orange"
            small
            @click="showTextAssgntsModal = true"
            >Text Assgnts</BaseButton
          >
        </VFlex>
      </VLayout>
    </VContainer>
    <TextAssgntsModal
      v-if="showTextAssgntsModal && $route.params.assetType === 'laborer'"
      :show="showTextAssgntsModal"
      @hide="showTextAssgntsModal = false"
    ></TextAssgntsModal>
  </div>
</template>

<script>
import dayjs from '@utils/dayjsCustomParse'
import WithFilters from '@dataProviders/WithFilters'
import TextAssgntsModal from '@modals/TextAssgntsModal'

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DispatcherReportsListHeader',
  components: { WithFilters, TextAssgntsModal },
  data() {
    return {
      isSearchFocused: false,
      showTextAssgntsModal: false,
    }
  },
  computed: {
    ...mapGetters(['getReportSearchTerm', 'numSelectedAssetsAndAssgnts']),
    isSearchFullWidth() {
      return !!this.getReportSearchTerm.length || this.isSearchFocused
    }, // isSearchFullWidth
    showCondensedControls() {
      return ['ml', 'md', 'sm', 'xs'].includes(this.$mq)
    }, // showCondensedControls
  },
  methods: {
    ...mapActions([
      'updateReportSearchTerm',
      'clearReportSearchTerm',
      'updateForemanFilter',
      'clearForemanFilter',
      'deselectAllAssgntsAndAssets',
    ]),
    // TODO (ES) : Move this to vuex
    nextDay() {
      this.$router.push({
        params: {
          ...this.$route.params,
          date: dayjs(this.$route.params.date, 'MM-DD-YYYY')
            .add(1, 'day')
            .format('MM-DD-YYYY'),
        },
      })
    }, // nextDay

    // TODO (ES) : Move this to vuex
    previousDay() {
      this.$router.push({
        params: {
          ...this.$route.params,
          date: dayjs(this.$route.params.date, 'MM-DD-YYYY')
            .subtract(1, 'day')
            .format('MM-DD-YYYY'),
        },
      })
    }, // previousDay

    // TODO (ES) : Move this to vuex
    gotoToday() {
      this.$router.push({
        params: {
          ...this.$route.params,
          date: dayjs().format('MM-DD-YYYY'),
        },
      })
    }, // gotoToday

    textAssgnts() {}, // textAssgnts
  },
}
</script>
