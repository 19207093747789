var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{class:[
    'asset-tile assgnt-tile assigned my-2 py-2 px-2',
    { blank: !_vm.label },
    { selected: _vm.isSelected },
    { ghost: _vm.assgnt.isGhost },
    { 'elevation-0': _vm.assgnt.isGhost },
    { processing: _vm.isProcessing },
    { 'asset-hover green accent-2 black--text': _vm.isMyAssetHovering },
    { 'elevation-6': _vm.isSelected },
    { 'multi-assigned': _vm.multiAssigned },
    `type--${_vm.laborOrEquipType}`,
  ],on:{"click":_vm.toggleSelection,"mouseover":_vm.hoverFn,"mouseleave":_vm.unhoverFn}},[_c('VLayout',{attrs:{"row":"","align-content-center":""}},[(_vm.getIsSMSStatusVisible && _vm.smsStatus !== 'hide')?_c('VAvatar',{class:`sms-status--icon mr-2 sms-status sms-status--${_vm.smsStatus}`,attrs:{"size":"20"}},[_c('VIcon',{attrs:{"fab":"","title":_vm.smsStatus}},[_vm._v(_vm._s(_vm.smsStatusIcon))])],1):_vm._e(),(_vm.multiAssigned && !_vm.isGhost)?_c('VAvatar',{staticClass:"num-assignments flex",attrs:{"left":"","size":"20"}},[_vm._v(_vm._s(_vm.numAssignments))]):_vm._e(),(_vm.isProcessing || _vm.isGhost)?_c('BaseSpinner',{staticClass:"pr-2 d-inline-block",attrs:{"size":"small","margin":"0","line-fg-color":_vm.isGhost ? '#DDD' : '#FFF',"line-bg-color":_vm.isGhost ? '#666' : '#000'}}):_vm._e(),_c('VFlex',{attrs:{"grow":""}},[_vm._v(" "+_vm._s(_vm.label || 'BLANK RECORD')+" ")]),_c('VFlex',{attrs:{"shrink":""}},[(_vm.isSelected)?_c('ContextualMenu',{staticClass:"more-icon",attrs:{"items":_vm.menuItems,"button":{ small: true, color: 'white', flat: true }}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }