<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLocationLoading: false,
      locationError: null,
      placeholderLocation: {
        JOB_NUMBER: '',
        CLIENT_NAME: '',
        IDENTIFIER: '',
        PHASE_: '',
        LOCATION: { street: 'Street', city: 'City', state: 'NY' },
        PERMIT_START_TIME: '',
        PERMIT_END_TIME: '',
      },
    }
  },
  computed: {
    ...mapGetters(['getLocationFromId']),
    location() {
      return this.getLocationFromId(this.id) || this.placeholderLocation
    },
    address() {
      const obj = this.location.LOCATION
      return obj ? `${obj.street}\n${obj.city}, ${obj.state}` : ''
    },
    permitInfo() {
      let s = this.location.PERMIT_START_TIME
      let e = this.location.PERMIT_END_TIME
      let startString = s
        ? `${Number(s.hours)}:${s.minutes}${s.am_pm.toLowerCase()}`
        : ''
      let endString = e
        ? ` to ${Number(e.hours)}:${e.minutes}${e.am_pm.toLowerCase()}`
        : ''
      return `${startString}${endString}`
    }, // permitInfo
    metaFields() {
      return [
        { title: 'Job Number', value: this.location.JOB_NUMBER },
        { title: 'Client', value: this.location.CLIENT_NAME },
        { title: 'Address', value: this.address },
        { title: 'Loc/Node', value: this.location.IDENTIFIER },
        { title: 'Phase', value: this.location.PHASE_ },
        { title: 'Permit Info', value: this.permitInfo },
      ]
    }, // metaFields
  },
  watch: {
    id: {
      async handler(id) {
        this.isLocationLoading = true
        try {
          await this.fetchLocation({ ID: id })
        } catch (error) {
          this.locationError = true
          throw Promise.reject(error)
        } finally {
          this.isLocationLoading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchLocation']),
  },
  render() {
    const {
      location,
      isLocationLoading,
      address,
      permitInfo,
      metaFields,
      locationError,
    } = this
    return this.$scopedSlots.default({
      location,
      isLocationLoading,
      address,
      permitInfo,
      metaFields,
      locationError,
    })
  },
}
</script>
