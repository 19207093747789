<template>
  <VDialog
    v-model="showPopup"
    :max-width="500"
    :disabled="working"
    :persistent="working"
  >
    <VCard :class="['location-popup text-xs-left', { 'grey--text': working }]">
      <ModalCloseButton :disabled="working" @click="$emit('hide')" />
      <VCardTitle class="cyan accent-3">
        <VFlex v-if="isLoading" xs12 class="title font-weight-bold"
          >Loading Location...</VFlex
        >
        <template v-else>
          <VFlex xs12 mb-2>{{ location.CLIENT_NAME }}</VFlex>
          <VFlex xs12 class="title font-weight-bold">{{
            location.JOB_NUMBER
          }}</VFlex>
          <VFlex xs12 class="subtitle">{{ location.IDENTIFIER }}</VFlex>
        </template>
      </VCardTitle>
      <transition name="fade-fast" mode="out-in">
        <VCardText v-if="!isLoading" class="font-weight-medium">
          <LocationModalData
            title="Address"
            :value="
              [
                location.LOCATION.street,
                location.LOCATION.city,
                location.LOCATION.state,
              ].join(', ') || ''
            "
            width="12"
          />
          <LocationModalData
            title="Permit Dates"
            :value="
              `${location.PERMITTED_START_DATE.date} – ${location.PERMITTED_END_DATE.date}`
            "
            width="12"
          />
          <LocationModalData
            title="Work Hours"
            :value="
              `${getTime(location.PERMIT_START_TIME)} – ${getTime(
                location.PERMIT_END_TIME
              )}`
            "
            width="12"
          />
          <BaseInput
            v-model="PROJECT_MANAGER_NOTES"
            :disabled="isNotEditable"
            label="PM's Location Notes (40 Character limit)"
            color="blue"
            maxlength="40"
            counter="40"
          />
          <VLayout wrap py-2>
            <LocationModalData
              title="Phase"
              :value="location.PHASE_ || 'n/a'"
            />
            <LocationModalData title="LF">
              <BaseInput
                v-model="LF"
                :disabled="isNotEditable"
                hide-details
                type="number"
                class="pt-0 mt-0"
              />
            </LocationModalData>
            <LocationModalData
              title="Status"
              :value="location.STATUS || 'n/a'"
            />
            <VFlex class="xs6 mb-2">
              <span class="font-weight-bold mr-2">Protected :</span>
              <VRadioGroup
                v-model="isProtected"
                row
                hide-details
                :disabled="isNotEditable"
                class="mt-0 "
              >
                <VRadio
                  v-for="[label, value] in [
                    ['Yes', 'P'],
                    ['No', 'NP'],
                    ['Both', 'P/NP'],
                  ]"
                  :key="label"
                  :value="label"
                  class="mt-0"
                  color="blue accent-2"
                  :label="value"
                />
              </VRadioGroup>
            </VFlex>
            <LocationModalData title="Job Type" :value="jobType" />
            <LocationModalData title="VZ Insp Required?">
              <VSwitch
                v-model="isVzInspectionRequired"
                class="mt-0"
                color="blue accent-2"
                :disabled="isNotEditable"
                :label="isVzInspectionRequired ? 'Yes' : 'No'"
                hide-details
              />
            </LocationModalData>
            <LocationModalData title="On Hold?">
              <VSwitch
                v-model="onHold"
                class="mt-0"
                color="orange accent-2"
                :disabled="isNotEditable"
                :label="onHold ? 'Yes' : 'No'"
                hide-details
              />
            </LocationModalData>

            <VMenu
              v-model="showVzInspectionDatePicker"
              :close-on-content-click="false"
              full-width
              max-width="500"
              :disabled="isNotEditable"
            >
              <template slot="activator">
                <VTextField
                  :value="formattedVzInspectionDate"
                  clearable
                  :disabled="isNotEditable"
                  label="VZ Inpsection Date"
                  readonly
                  @click:clear="vzInspectionDate = null"
                ></VTextField>
              </template>
              <VDatePicker
                v-model="vzInspectionDate"
                :landscape="false"
                full-width
                header-color="blue darken-2"
                :disabled="isNotEditable"
                @change="showVzInspectionDatePicker = false"
              ></VDatePicker>
            </VMenu>

            <!-- Spacer -->
            <VFlex :class="`xs12 sm6 mb-2 location-modal-data`" />

            <VMenu
              v-model="showInstall5gFoundationDatePicker"
              :close-on-content-click="false"
              full-width
              max-width="500"
              :disabled="isNotEditable"
            >
              <template slot="activator">
                <VTextField
                  :value="formattedInstall5gFoundationDate"
                  clearable
                  :disabled="isNotEditable"
                  label="5G Foundation Date"
                  readonly
                  @click:clear="install5gFoundationDate = null"
                ></VTextField>
              </template>
              <VDatePicker
                v-model="install5gFoundationDate"
                :landscape="false"
                full-width
                header-color="blue darken-2"
                :disabled="isNotEditable"
                @change="showInstall5gFoundationDatePicker = false"
              ></VDatePicker>
            </VMenu>
          </VLayout>

          <LocationModalData
            v-if="location.OWNER_REPRESENTATIVE"
            title="Owner Rep"
            :value="location.OWNER_REPRESENTATIVE"
          />
        </VCardText>
      </transition>
      <LocationModalFooter
        :location="location"
        :working="working"
        :dirty="dirty"
        :view-only="viewOnly"
        @saveLocation="saveLocation"
        @hide="hide"
      />
    </VCard>
  </VDialog>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'

import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'
import VSwitch from '@vuetify/VSwitch'
import { VRadioGroup, VRadio } from '@vuetify/VRadioGroup'
import { VTextField, VDatePicker } from 'vuetify/lib'
import _get from 'lodash/get'

import LocationModalData from '@modals/LocationModalData'
import LocationModalFooter from '@modals/LocationModalFooter'
import ModalCloseButton from '@modals/ModalCloseButton'

export default {
  name: 'LocationModal',
  components: {
    VDialog,
    VSwitch,
    VRadioGroup,
    VRadio,
    VCardText,
    VTextField,
    VDatePicker,
    LocationModalFooter,
    LocationModalData,
    ModalCloseButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      working: false,
      PROJECT_MANAGER_NOTES: this.location.PROJECT_MANAGER_NOTES,
      LF: this.location.LF,
      VZ_INSPECTION_DATE: this.location.VZ_INSPECTION_DATE.date || null,
      INSTALL_5G_FOUNDATION_DATE:
        this.location.INSTALL_5G_FOUNDATION_DATE.date || null,
      showVzInspectionDatePicker: false,
      showInstall5gFoundationDatePicker: false,
    }
  },
  computed: {
    ...mapGetters(['getLocationFromId', 'canEditScheduler']),
    jobType() {
      return typeof this.location.JOB_TYPE === 'object'
        ? this.location.JOB_TYPE.join(', ')
        : this.location.JOB_TYPE
        ? this.location.JOB_TYPE
        : '-'
    }, // jobType
    showPopup: {
      get() {
        return this.show
      },
      set(val) {
        if (!val) {
          this.$emit('hide')
        }
      },
    }, // showPopup

    onHold: {
      get() {
        return this.location.ON_HOLD
      },
      async set(val) {
        this.working = true
        this.$notify({
          title: `Updating On Hold to ${val ? '"Yes"' : '"No"'}`,
          group: 'progress',
        })
        try {
          await this.updateLocation({
            ID: this.location.ID,
            ON_HOLD: val,
          })
          let updatedLoc = await this.getLocationFromId(this.location.ID)
          this.successAndClearProgress(
            `On Hold updated to ${updatedLoc.ON_HOLD ? '"Yes"' : '"No"'}`
          )
        } catch (error) {
          this.warnAndClearProgress(`Error Updating On Hold Status`)
        }
      },
    }, // onHold

    isVzInspectionRequired: {
      get() {
        return this.location.VZ_INSPECTION_REQUIRED
      },
      async set(val) {
        await this.updateLocationKey('VZ_INSPECTION_REQUIRED', val)
      },
    }, // isVzInspectionRequired

    vzInspectionDate: {
      get() {
        let dateString = _get(
          this,
          'VZ_INSPECTION_DATE.date',
          _get(this, 'VZ_INSPECTION_DATE', null)
        )

        return dateString ? dayjs(dateString).format('YYYY-MM-DD') : null
      },
      async set(val) {
        console.log('setting date to : ', val)
        this.VZ_INSPECTION_DATE = val
      },
    }, // vzInspectionDate

    formattedVzInspectionDate() {
      return _get(this, 'VZ_INSPECTION_DATE')
        ? dayjs(_get(this, 'VZ_INSPECTION_DATE')).format('MM/DD/YYYY')
        : null
    }, // formattedVzInspectionDate

    isVzInspectionDateDirty() {
      if (!this.VZ_INSPECTION_DATE && !this.location.VZ_INSPECTION_DATE.date)
        return false
      return (
        dayjs(this.VZ_INSPECTION_DATE).format('MM/DD/YYYY') !==
        dayjs(this.location.VZ_INSPECTION_DATE.date).format('MM/DD/YYYY')
      )
    }, // isVzInspectionDateDirty

    install5gFoundationDate: {
      get() {
        let dateString = _get(
          this,
          'INSTALL_5G_FOUNDATION_DATE.date',
          _get(this, 'INSTALL_5G_FOUNDATION_DATE', null)
        )

        return dateString ? dayjs(dateString).format('YYYY-MM-DD') : null
      },
      async set(val) {
        console.log('setting date to : ', val)
        this.INSTALL_5G_FOUNDATION_DATE = val
      },
    }, // install5gFoundationDate

    formattedInstall5gFoundationDate() {
      return _get(this, 'INSTALL_5G_FOUNDATION_DATE')
        ? dayjs(_get(this, 'INSTALL_5G_FOUNDATION_DATE')).format('MM/DD/YYYY')
        : null
    }, // formattedInstall5gFoundationDate

    isInstall5gFoundationDateDirty() {
      if (
        !this.INSTALL_5G_FOUNDATION_DATE &&
        !this.location.INSTALL_5G_FOUNDATION_DATE.date
      )
        return false
      return (
        dayjs(this.INSTALL_5G_FOUNDATION_DATE).format('MM/DD/YYYY') !==
        dayjs(this.location.INSTALL_5G_FOUNDATION_DATE.date).format(
          'MM/DD/YYYY'
        )
      )
    }, // isInstall5gFoundationDateDirty

    isProtected: {
      get() {
        return this.location.PROTECTED
      },
      async set(val) {
        await this.updateLocationKey('PROTECTED', val)
      },
    }, // isProtected

    dirty() {
      return (
        this.PROJECT_MANAGER_NOTES !== this.location.PROJECT_MANAGER_NOTES ||
        Number(this.LF) !== Number(this.location.LF) ||
        this.isVzInspectionDateDirty ||
        this.isInstall5gFoundationDateDirty
      )
    }, // dirty

    isNotEditable() {
      return this.working || this.viewOnly
    }, // isNotEditable
  },
  methods: {
    ...mapActions(['updateLocation']),
    hide() {
      this.$emit('hide')
    }, // hide
    getTime(timeObj) {
      return timeObj ? dayjs(timeObj.timestamp).format('h:mm A') : false
    }, // getTime
    async saveLocation() {
      this.working = true
      await this.$notify({ title: `Updating Location...`, group: 'progress' })
      try {
        await this.updateLocation({
          ID: this.location.ID,
          PROJECT_MANAGER_NOTES: this.PROJECT_MANAGER_NOTES,
          LF: Number(this.LF),
          VZ_INSPECTION_DATE: this.VZ_INSPECTION_DATE,
          INSTALL_5G_FOUNDATION_DATE: this.INSTALL_5G_FOUNDATION_DATE,
        })
        this.successAndClearProgress('Location Updated')
        this.hide()
      } catch (error) {
        this.warnAndClearProgress(`Error Updating Location`)
      }
    }, // saveLocation

    async updateLocationKey(key, val) {
      this.working = true
      await this.$notify({ title: `Updating Location...`, group: 'progress' })
      try {
        await this.updateLocation({
          ID: this.location.ID,
          [key]: val,
        })
        this.successAndClearProgress('Location Updated')
      } catch (error) {
        this.warnAndClearProgress(`Error Updating ${key}`)
      }
    }, // saveLocation
    successAndClearProgress(title) {
      this.notifyAndClearProgress(title)
    },
    warnAndClearProgress(title) {
      this.notifyAndClearProgress(title, 'warn')
    },
    notifyAndClearProgress(title, type = 'success') {
      this.working = false

      this.$notify({ group: 'progress', clean: true })
      this.$notify({ type, title })
    },
  },
}
</script>

<style lang="scss">
.location-popup {
  .v-card__title {
    .title,
    .subtitle {
      font-family: 'Avenir', sans-serif !important;
      font-weight: 500;
      color: black;
    }
    .subtitle {
      margin-top: 0.3em;
    }
  }
}
</style>
