<template>
  <Layout>
    <Dispatcher :date="params.date" :asset-type="params.assetType" />
  </Layout>
</template>

<script>
import Layout from '@layouts/fullHeight'
import Dispatcher from '@dispatcher/Dispatcher'
import { AssetTypes } from '@constants/knack'
import { createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'DispatcherView',
  metaInfo() {
    let type =
      this.params.assetType === AssetTypes.LABORER ? 'Laborers' : 'Equipment'
    let title = `${type} Dispatch - ${this.params.date}`
    return {
      title,
    }
  },
  components: { Layout, Dispatcher },
  computed: {
    ...routeState(['params']),
  },
}
</script>
