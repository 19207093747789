<template>
  <VDialog value="show" :max-width="400" :persistent="true">
    <VCard class="text-xs-center">
      <VCardTitle class="orange accent-2 white--text">
        <VFlex xs12 mb-2
          ><h2>
            Confirm Delete Assets
          </h2></VFlex
        >
      </VCardTitle>
      <VCardText class="font-weight-medium pa-5">
        <p class="px-2">{{ body }}</p>
        <p><strong>This cannot be undone</strong></p>
        <BaseButton color="red" outline @click="confirmDelete"
          >Delete Assets</BaseButton
        >
        <BaseButton color="blue" outline @click="$emit('hide')"
          >Cancel</BaseButton
        >
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'AssgntDeleteModal',
  components: {
    VDialog,
    VCardText,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    body: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    showPopup: {
      get() {
        return this.show
      },
      set(val) {
        if (!val) {
          this.$emit('hide')
        }
      },
    },
  },
  methods: {
    confirmDelete() {
      this.$emit('confirmDelete')
      this.$emit('hide')
    }, // confirmDelete()
  }, // methods()
}
</script>

<style lang="scss" scoped>
.vue-dialog {
  color: $gray-body;
  .dialog-content {
    text-align: center;
  }

  .vue-dialog-buttons {
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0.9rem;
    button {
      text-transform: uppercase;
      transition: background-color, 0.3s ease, color 0.3s ease;
    }
  }
}
</style>
