<template>
  <WithAssetsAndAssignments :date="$route.params.date">
    <div
      slot-scope="{ allAssets, assignedAssets, availableAssets, isDataLoading }"
    >
      <div class="dispatcher-wrap">
        <VNavigationDrawer
          v-model="$store.state.nav.availableAssets"
          hide-overlay
          app
          light
          class="grey lighten-1"
        >
          <VListTile>
            <VLayout>
              <VFlex shrink class="close-drawer-btn">
                <VListTileAction
                  @click="$store.commit('TOGGLE_DRAWER', 'availableAssets')"
                >
                  <VIcon>keyboard_arrow_left</VIcon>
                </VListTileAction>
              </VFlex>
              <VFlex grow>
                <BaseSearch
                  :outline="false"
                  color="blue"
                  :placeholder="`Search for ${$route.params.assetType}`"
                  @searchTermUpdate="assetSearchString = $event"
                  @clear="clearSearch"
                />
              </VFlex>
            </VLayout>
          </VListTile>
          <VListTile>
            <VContainer pa-0>
              <VLayout>
                <VFlex>
                  <BaseDropdown
                    v-if="assetType === 'laborer'"
                    dark
                    title="Sort Laborers by..."
                    :options="[
                      { name: 'Rating', value: 'LABORER_RATING' },
                      {
                        name: 'Default Foreman',
                        value: `defaultForeman`,
                      },
                      {
                        name: 'Num Assignments',
                        value: 'numAssignments',
                      },
                    ]"
                    clearable
                    hide-details
                    :value="selectedLaborerSort"
                    @click:clear="selectedLaborerSort = null"
                    @selected="selectedLaborerSort = $event"
                  />
                  <transition name="fade-fast" mode="out-in">
                    <BaseSpinner
                      v-if="isDataLoading"
                      message="Loading Assets"
                    />

                    <AvailableAssets
                      v-else
                      :sort="selectedLaborerSort"
                      :assets="allAssets"
                      :search-string="assetSearchString"
                    />
                  </transition>
                </VFlex>
              </VLayout>
            </VContainer>
          </VListTile>
        </VNavigationDrawer>
        <VContainer fluid pa-0 class="dispatcher" fill-height>
          <VLayout wrap>
            <BaseButton
              v-if="!$store.state.nav.availableAssets"
              @click="$store.commit('TOGGLE_DRAWER', 'availableAssets')"
              >Open Assets Drawer</BaseButton
            >
            <VFlex
              v-if="numSelectedAssetsAndAssgnts && ['sm', 'xs'].includes($mq)"
              text-xs-right
              pb-0
            >
              <span>{{ numSelectedAssetsAndAssgnts }} Selected</span>
              <BaseButton
                color="blue"
                md-icon="clear"
                small
                icon
                no-icon-padding
                title="Deselect All"
                @click="deselectAllAssgntsAndAssets"
              />
            </VFlex>
            <VFlex sm12>
              <DispatcherReportsListHeader />
              <DispatcherReportsList v-if="!isDataLoading" />
            </VFlex>
          </VLayout>
        </VContainer>
      </div>
    </div>
  </WithAssetsAndAssignments>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AvailableAssets from '@dispatcher/AvailableAssets'
import WithAssetsAndAssignments from '@dataProviders/WithAssetsAndAssignments'
import DispatcherReportsListHeader from '@dispatcher/DispatcherReportsListHeader'
import DispatcherReportsList from '@dispatcher/DispatcherReportsList'
export default {
  name: 'Dispatcher',
  components: {
    WithAssetsAndAssignments,
    AvailableAssets,
    DispatcherReportsList,
    DispatcherReportsListHeader,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    assetType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      assetSearchString: false,
      selectedLaborerSort: null,
    }
  },
  computed: {
    ...mapGetters(['numSelectedAssetsAndAssgnts']),
  },
  methods: {
    ...mapActions(['deselectAllAssgntsAndAssets']),
    clearSearch() {
      this.assetSearchString = false
    }, // clearSearch
  },
}
</script>
<style lang="scss" scoped>
.close-drawer-btn {
  cursor: pointer;
  &,
  .v-list__tile__action {
    min-width: 30px;
  }
}
</style>
