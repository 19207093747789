var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assets-list"},[_c('VCard',{class:[
      'dispatcher-report-card text-sm-left',
      _vm.isNightShift ? 'night-shift' : 'grey lighten-2',
    ]},[_c('DispatcherReportCardHeader',{on:{"selectAll":_vm.selectAllAssgnts,"removeAll":_vm.removeAllAssgnts,"selectDefaultCrew":_vm.selectDefaultCrew,"assignSelectedAssets":_vm.assignSelectedAssets,"showLocationModal":_vm.showLocationModalAction}},[_c('RouterLink',{staticClass:"report-link-btn",attrs:{"title":"View Report","to":`/locations/${_vm.report.LOCATION[0].id}/dailys/${_vm.report.ID}`}},[(_vm.isNightShift)?_c('VIcon',{staticClass:"night-shift-icon mr-1",attrs:{"color":"#136085"}},[_vm._v("brightness_3")]):_vm._e(),_vm._v(_vm._s(_vm.report.JOB_NUMBER)+" ")],1)],1),(_vm.showLocationModal)?_c('WithLocation',{attrs:{"id":_vm.report.LOCATION[0].id},scopedSlots:_vm._u([{key:"default",fn:function({ isLocationLoading, location }){return [_c('LocationModal',{attrs:{"is-loading":isLocationLoading,"location":location,"view-only":true,"show":_vm.showLocationModal},on:{"hide":function($event){_vm.showLocationModal = false}}})]}}],null,false,894840770)}):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"header mb-2"},[_c('div',{staticClass:"header-detail"},[_vm._v(_vm._s(_vm.report.LOCATION_IDENTIFIER))]),_c('VLayout',[_c('VFlex',{staticClass:"header-detail"},[_c('VIcon',{staticClass:"foreman-icon"},[_vm._v("account_circle")]),_vm._v(_vm._s(_vm.report.FOREMANS_NAME))],1),(_vm.reportAssignments.length)?_c('VFlex',{staticClass:"header-detail num-assets shrink"},[_vm._v(_vm._s(_vm.numAssets))]):_vm._e()],1)],1),_c('div',{staticClass:"assigned-assets"},[_c('Draggable',{class:[
            'draggable-assets column',
            { empty: _vm.reportAssignments.length === 0 },
          ],attrs:{"group":{ name: 'assets' },"draggable":".asset-tile:not(.ghost):not(.processing)","value":_vm.sortAssets(_vm.reportAssignments),"sort":false},on:{"change":_vm.changeAsset}},_vm._l((_vm.sortAssets(_vm.reportAssignments)),function(assgnt){return _c('AssignmentTile',{key:`${_vm.report.ID}-${assgnt.ID}`,staticClass:"assigned mr-1",attrs:{"assgnt-id":assgnt.ID,"label":_vm.assetType === 'laborer'
                ? assgnt.LABORER_NAME
                : assgnt.EQUIPMENT_NAME},on:{"confirmDeleteAll":function($event){_vm.showConfirmRemoveAllModal = true}}})}),1)],1),(_vm.showAlreadyAssignedModal)?[_c('AssetAlreadyAssignedModal',{attrs:{"show":_vm.showAlreadyAssignedModal,"asset":_vm.getMultiAssgnObj.asset,"assignments":_vm.getMultiAssgnObj.currentAssgnts},on:{"confirmAssignment":_vm.confirmAssignmentAction,"hide":_vm.hideAssgntModalAction}})]:_vm._e(),(_vm.showConfirmRemoveAllModal)?_c('AssgntDeleteModal',{attrs:{"show":_vm.showConfirmRemoveAllModal,"body":"Are you sure you want to remove all selected assets from their reports?"},on:{"hide":function($event){_vm.showConfirmRemoveAllModal = false},"confirmDelete":_vm.removeSelectedAssgnts}}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }