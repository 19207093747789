<template>
  <VCard
    :class="[
      'asset-tile assgnt-tile assigned my-2 py-2 px-2',
      { blank: !label },
      { selected: isSelected },
      { ghost: assgnt.isGhost },
      { 'elevation-0': assgnt.isGhost },
      { processing: isProcessing },
      { 'asset-hover green accent-2 black--text': isMyAssetHovering },
      { 'elevation-6': isSelected },
      { 'multi-assigned': multiAssigned },
      `type--${laborOrEquipType}`,
    ]"
    @click="toggleSelection"
    @mouseover="hoverFn"
    @mouseleave="unhoverFn"
  >
    <VLayout row align-content-center>
      <VAvatar
        v-if="getIsSMSStatusVisible && smsStatus !== 'hide'"
        :class="`sms-status--icon mr-2 sms-status sms-status--${smsStatus}`"
        size="20"
        ><VIcon fab :title="smsStatus">{{ smsStatusIcon }}</VIcon></VAvatar
      >
      <VAvatar
        v-if="multiAssigned && !isGhost"
        left
        size="20"
        class="num-assignments flex"
        >{{ numAssignments }}</VAvatar
      >
      <BaseSpinner
        v-if="isProcessing || isGhost"
        size="small"
        margin="0"
        :line-fg-color="isGhost ? '#DDD' : '#FFF'"
        :line-bg-color="isGhost ? '#666' : '#000'"
        class="pr-2 d-inline-block"
      />
      <VFlex grow>
        {{ label || 'BLANK RECORD' }}
      </VFlex>
      <VFlex shrink>
        <ContextualMenu
          v-if="isSelected"
          class="more-icon"
          :items="menuItems"
          :button="{ small: true, color: 'white', flat: true }"
        />
      </VFlex>
    </VLayout>
  </VCard>
</template>

<script>
import VAvatar from '@vuetify/VAvatar'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'

import { mapActions, mapGetters } from 'vuex'

import ContextualMenu from '@dispatcher/ContextualMenu'

export default {
  name: 'AssignmentTile',
  components: { ContextualMenu, VAvatar },

  props: {
    assgntId: {
      type: [String, Number],
      required: true,
      default: -1,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAssgnt',
      'getSelectedAssgnts',
      'getReportsResponse',
      'getIsAssgntSelected',
      'getIsAssetAssigned',
      'getHoveringAssetId',
      'getAssetAssignmentKey',
      'getIsSMSStatusVisible',
    ]),
    assgnt() {
      return this.getAssgnt(this.computedAssetType, this.assgntId)
    }, // assgnt

    computedAssetType() {
      return this.assetType || this.$route.params.assetType
    }, // computedAssetType

    isSelected() {
      return this.getIsAssgntSelected(this.assgnt.ID) && !this.isProcessing
    }, // isSelected

    numAssignments() {
      return this.getIsAssetAssigned(this.assetType, this.myAssetId)
    }, // numAssignments

    multiAssigned() {
      return this.numAssignments && this.numAssignments > 1
    }, // multiAssigned

    isProcessing() {
      return this.assgnt.isProcessing
    }, // isProcessing

    isGhost() {
      return !!this.assgnt.isGhost || !!this.isProcessing
    }, // isGhost

    isMyAssetHovering() {
      return this.getHoveringAssetId === this.myAssetId
    },

    myAssetId() {
      let assgntKey = this.getAssetAssignmentKey(this.$route.params.assetType)
      return _get(this, `assgnt[${assgntKey}][0].id`)
    }, // myAssetId

    assetType() {
      return this.$route.params.assetType
    }, // assetType

    menuItems() {
      let items = [
        {
          title: `Remove Asset`,
          action: () => {
            this.tryToUnassignAsset({
              assetType: this.assetType,
              assgntId: this.assgnt.ID,
            })
          },
        },
      ]
      if (this.getSelectedAssgnts.length > 1) {
        items.push({
          title: `Remove (${this.getSelectedAssgnts.length}) Selected Assets`,
          action: () => {
            this.$emit('confirmDeleteAll')
          },
        })
      }
      items = [...items, ...this.assignToActions]
      return items
    }, // menuItems

    assignToReports() {
      return _sortBy(this.getReportsResponse.records, 'DAILY_REPORT_NAME')
    }, // assignToReports

    assignToActions() {
      return this.assignToReports.map((r, counter) => {
        return {
          header:
            counter === 0
              ? this.getSelectedAssgnts.length > 1
                ? 'Assign Selected Assets to...'
                : 'Assign This Asset to...'
              : false,
          title: r.DAILY_REPORT_NAME,
          action: () => {
            let { assetType } = this
            this.getSelectedAssgnts.forEach((assgntId) => {
              let assgnt = this.getAssgnt(assetType, assgntId)
              this.tryToReassignAsset({ assetType, assgnt, reportId: r.ID })
            })
          },
        }
      })
    }, // assignToActions

    laborOrEquipType() {
      return this.$route.params.assetType === 'laborer'
        ? // note "LABAORERTYPE" vs "asset.LABORER_TYPE"
          this.assgnt.LABORERTYPE
          ? this.assgnt.LABORERTYPE.toLowerCase()
          : 'laborer'
        : 'equip'
    }, // laborOrEquipType

    smsStatus() {
      let status = _get(this.assgnt, 'ASSIGNMENT_SMS_STATUS', '').length
        ? this.assgnt.ASSIGNMENT_SMS_STATUS
        : 'Unsent'
      return this.getIsSMSStatusVisible ? status : 'hide'
    }, // smsStatus

    smsStatusIcon() {
      let icons = {
        hide: '',
        Unsent: 'radio_button_unchecked',
        SendSuccess: 'phone_forwarded',
        SendFailure: 'error',
        Confirmed: 'done',
        NonConfirmed: 'warning',
        NoReply: 'call_missed_outgoing',
      }
      return icons[this.smsStatus]
    }, // smsStatusIcon
  },
  methods: {
    ...mapActions([
      'selectAssgnt',
      'deselectAssgnt',
      'setAssetHover',
      'unsetAssetHover',
      'tryToUnassignAsset',
      'tryToReassignAsset',
    ]),
    toggleSelection() {
      if (this.isGhost) {
        return false
      }
      if (this.isSelected) {
        this.deselectAssgnt(this.assgnt.ID)
      } else {
        this.selectAssgnt(this.assgnt.ID)
      }
    }, // toggleSelection

    hoverFn() {
      // Only run this if there's another
      // assgnt out there to highlight
      if (!this.multiAssigned || this.isGhost) return false
      if (!this.hover) {
        this.setAssetHover(this.myAssetId)
      }
      this.hover = true
    }, // hoverFn

    unhoverFn() {
      // Only run this if there's another
      // assgnt out there to highlight
      if (!this.multiAssigned || this.isGhost) return false
      this.hover = false
      this.unsetAssetHover()
    }, // unhoverFn
  },
}
</script>

<style lang="scss" scoped>
.asset-tile {
  font-size: 0.8em;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;

  &.sortable-ghost {
    opacity: 0.3;
  }
  &.selected {
    color: white;
    background-color: $blue;
    transform: translateY(-2px);
    &.asset-hover {
      color: $blue !important;
    }
  }
  &.ghost {
    color: white;
    cursor: not-allowed;
    background-color: $gray-mid;
    border: 2px dashed white;
    opacity: 0.8;
  }
  &.blank {
    color: white;
    background-color: $red;
    opacity: 0.8;
    &.selected {
      background-color: darken($red, 20);
    }
  }
  &.processing {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 3px;
    content: '';
  }

  &.type--operator {
    &::after {
      z-index: 1;
      background-color: $navbar-red;
    }
  }

  &.type--foreman {
    &::after {
      z-index: 1;
      background-color: $purple;
    }
  }

  .num-assignments {
    flex: 0 0 auto;
    margin-right: 4px;
    line-height: 1;
    color: white;
    background-color: $orange;
    border-radius: 100% !important;
  }
  .more-icon {
    position: absolute;
    top: 0;
    right: 0;
    .v-btn--small {
      width: 23px !important;
      height: 23px;
    }
  }
  .sms-status {
    &--SendSuccess .v-icon {
      color: $blue;
    }
    &--SendFailure .v-icon {
      color: $red;
    }
    &--Confirmed .v-icon {
      color: $green;
    }
    &--NonConfirmed .v-icon {
      color: $orange;
    }
    &--NoReply .v-icon {
      color: $red;
    }
  }
}
</style>
