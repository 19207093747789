var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WithAssetsAndAssignments',{attrs:{"date":_vm.$route.params.date},scopedSlots:_vm._u([{key:"default",fn:function({ allAssets, assignedAssets, availableAssets, isDataLoading }){return _c('div',{},[_c('div',{staticClass:"dispatcher-wrap"},[_c('VNavigationDrawer',{staticClass:"grey lighten-1",attrs:{"hide-overlay":"","app":"","light":""},model:{value:(_vm.$store.state.nav.availableAssets),callback:function ($$v) {_vm.$set(_vm.$store.state.nav, "availableAssets", $$v)},expression:"$store.state.nav.availableAssets"}},[_c('VListTile',[_c('VLayout',[_c('VFlex',{staticClass:"close-drawer-btn",attrs:{"shrink":""}},[_c('VListTileAction',{on:{"click":function($event){return _vm.$store.commit('TOGGLE_DRAWER', 'availableAssets')}}},[_c('VIcon',[_vm._v("keyboard_arrow_left")])],1)],1),_c('VFlex',{attrs:{"grow":""}},[_c('BaseSearch',{attrs:{"outline":false,"color":"blue","placeholder":`Search for ${_vm.$route.params.assetType}`},on:{"searchTermUpdate":function($event){_vm.assetSearchString = $event},"clear":_vm.clearSearch}})],1)],1)],1),_c('VListTile',[_c('VContainer',{attrs:{"pa-0":""}},[_c('VLayout',[_c('VFlex',[(_vm.assetType === 'laborer')?_c('BaseDropdown',{attrs:{"dark":"","title":"Sort Laborers by...","options":[
                    { name: 'Rating', value: 'LABORER_RATING' },
                    {
                      name: 'Default Foreman',
                      value: `defaultForeman`,
                    },
                    {
                      name: 'Num Assignments',
                      value: 'numAssignments',
                    },
                  ],"clearable":"","hide-details":"","value":_vm.selectedLaborerSort},on:{"click:clear":function($event){_vm.selectedLaborerSort = null},"selected":function($event){_vm.selectedLaborerSort = $event}}}):_vm._e(),_c('transition',{attrs:{"name":"fade-fast","mode":"out-in"}},[(isDataLoading)?_c('BaseSpinner',{attrs:{"message":"Loading Assets"}}):_c('AvailableAssets',{attrs:{"sort":_vm.selectedLaborerSort,"assets":allAssets,"search-string":_vm.assetSearchString}})],1)],1)],1)],1)],1)],1),_c('VContainer',{staticClass:"dispatcher",attrs:{"fluid":"","pa-0":"","fill-height":""}},[_c('VLayout',{attrs:{"wrap":""}},[(!_vm.$store.state.nav.availableAssets)?_c('BaseButton',{on:{"click":function($event){return _vm.$store.commit('TOGGLE_DRAWER', 'availableAssets')}}},[_vm._v("Open Assets Drawer")]):_vm._e(),(_vm.numSelectedAssetsAndAssgnts && ['sm', 'xs'].includes(_vm.$mq))?_c('VFlex',{attrs:{"text-xs-right":"","pb-0":""}},[_c('span',[_vm._v(_vm._s(_vm.numSelectedAssetsAndAssgnts)+" Selected")]),_c('BaseButton',{attrs:{"color":"blue","md-icon":"clear","small":"","icon":"","no-icon-padding":"","title":"Deselect All"},on:{"click":_vm.deselectAllAssgntsAndAssets}})],1):_vm._e(),_c('VFlex',{attrs:{"sm12":""}},[_c('DispatcherReportsListHeader'),(!isDataLoading)?_c('DispatcherReportsList'):_vm._e()],1)],1)],1)],1)])}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }