<template>
  <div class="available-assets">
    <!-- <VContainer fluid grid-list-sm py-0 px-3> -->
    <VLayout grid-list-md justify-center column>
      <template>
        <div v-if="!sortedAssets.length">No Assets Available</div>
        <Draggable
          v-else
          v-model="sortedAssets"
          :group="{ name: 'assets', pull: 'clone' }"
          draggable=".asset-tile"
          :sort="false"
          @change="onChange"
        >
          <AssetTile
            v-for="asset of sortedAssets"
            :key="asset.ID"
            :asset="asset"
          >
            <template slot="asset">
              <span
                >{{
                  assetType === 'laborer'
                    ? asset.LABORER_NAME
                    : asset.EQUIPMENT_NAME
                }}<span v-if="sort">
                  ({{ _get(asset, sort, '-') || '-' }})</span
                ></span
              >
            </template></AssetTile
          >
        </Draggable>
      </template>
    </VLayout>
    <!-- </VContainer> -->
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import fullstory from '@utils/fullstory'
import { mapGetters, mapActions } from 'vuex'
import { AssetTypes } from '@constants/knack'

import AssetTile from '@dispatcher/AssetTile'

import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import fuzzyMatch from '@utils/fuzzyMatch'

export default {
  name: 'AvailableAssets',
  components: { AssetTile, Draggable },
  props: {
    assets: {
      type: Array,
      required: true,
      default: () => {},
    },
    sort: {
      type: String,
      required: false,
      default: null,
    },
    searchString: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getIsAssetAssigned', 'getAssetNameFromAssgnt']),
    _get() {
      return _get
    },
    assetType() {
      return this.$route.params.assetType || null
    }, // assetType
    sortOrder() {
      let defaultSort =
        this.assetType === 'laborer' ? 'LABORER_NAME' : 'EQUIPMENT_NAME'
      return this.sort ? [this.sort, defaultSort] : defaultSort
    }, // sortOrder
    sortedAssets: {
      set() {
        // Don't do nothin. Just here to allow
        // dragging off another <Draggable> into this element
      },
      get() {
        let sorted
        if (this.sortOrder[0] === 'numAssignments') {
          let assetsWithNumAssignments = this.assets.map((asset) => {
            let numAssignments = this.getIsAssetAssigned(
              this.$route.params.assetType,
              asset.ID
            )
            return { ...asset, numAssignments }
          })
          sorted = _orderBy(assetsWithNumAssignments, this.sortOrder, [
            'desc',
            'asc',
          ])
        } else if (this.sortOrder[0] === 'defaultForeman') {
          let assetsWithForemen = this.assets.map((asset) => {
            let defaultForeman = _get(
              asset,
              'DEFAULT_FOREMAN[0].identifier',
              false
            )
            defaultForeman = defaultForeman
              ? defaultForeman
                  .split(' ')
                  .map((part) => `${part[0]}.`)
                  .join('')
              : 'N/A'
            return { ...asset, defaultForeman }
          })
          sorted = _orderBy(assetsWithForemen, this.sortOrder)
        } else {
          sorted = _sortBy(this.assets, this.sortOrder)
        }

        if (this.searchString) {
          sorted = fuzzyMatch(sorted, this.targetKey, this.searchString)
        }

        if (this.assetType === AssetTypes.LABORER) {
          sorted = sorted.filter((asset) => !asset.EXCLUDE_FROM_DISPATCHER)
        }

        return sorted
      },
    }, // sortedAssets
    targetKey() {
      return this.assetType === 'laborer' ? 'LABORER_NAME' : 'EQUIPMENT_NAME'
    }, // targetKey
  },
  methods: {
    ...mapActions(['tryToUnassignAsset', 'unsetAssetHover']),
    clearSearch() {
      this.searchString = false
    }, // clearSearch
    onStart(evt) {
      // console.log('assets', this.sortedAssets)
      // console.log('onStart evt', evt)
      // let theLocation = this.filteredLocations[evt.oldIndex]
      // console.log('theLocation', theLocation)
      // this.setTheDragObject(theLocation)
      // this.startDrag()
    }, // onStart

    async onChange(evt) {
      if (evt.added) {
        // If newly added item is a report, delete it from the cal
        // if (evt.added.element.locationData) {
        let assgnt = evt.added.element
        let assetName = this.getAssetNameFromAssgnt(
          this.assetType,
          evt.added.element
        )
        let unassignmentPayload = {
          assetName,
          assetType: this.assetType,
          assgnt: evt.added.element,
          reportId: _get(assgnt, 'DAILY_REPORT[0].id'),
        }
        try {
          await this.tryToUnassignAsset({
            assetType: this.assetType,
            assgntId: assgnt.ID,
          })
          this.$notify({ type: 'success', title: 'Asset Unassigned' })
          fullstory.event(`unassignment.ok`, unassignmentPayload)
        } catch (error) {
          this.$notify({ type: 'warn', title: 'Could not Unassign asset' })
          fullstory.event(`unassignment.fail`, unassignmentPayload)
          throw error
        }
        await this.unsetAssetHover()
      }
      return true
    }, // onChange
  },
}
</script>

<style lang="scss" scoped>
.available-assets ::v-deep .asset-tile {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  font-weight: 900;
  &.assigned {
    color: white;
    background-color: $gray-mid;
    border-color: white;
    &.selected {
      background-color: $blue;
    }
  }
}
</style>
