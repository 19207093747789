<template>
  <VCardActions class="location-modal-footer">
    <BaseButton
      color="blue darken-1"
      outline
      small
      :disabled="working"
      :to="`/locations/${location.ID}`"
      >View Location</BaseButton
    >

    <VSpacer></VSpacer>
    <BaseButton
      v-if="!viewOnly"
      :disabled="working || !dirty"
      depressed
      color="blue darken-1"
      @click="$emit('saveLocation')"
      >Save</BaseButton
    >
    <BaseButton
      color="blue darken-1"
      :disabled="working"
      outline
      @click="$emit('hide')"
      >Close</BaseButton
    >
  </VCardActions>
</template>

<script>
import { VCardActions } from '@vuetify/VCard'

export default {
  name: 'LocationModalFooter',
  components: { VCardActions },
  props: {
    location: {
      type: Object,
      default: () => {},
    },
    working: {
      type: Boolean,
      default: false,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>
