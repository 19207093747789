<template>
  <div class="text-assignment-item">
    <VIcon
      v-if="!laborer.phone"
      class="no-phone-icon mr-1 mb-1"
      title="No Phone Number"
      small
      fab
      >error</VIcon
    >
    {{ laborer.name }}
    <VAvatar
      v-if="laborer.numAssgnts > 1"
      right
      size="16"
      class="num-assignments"
      >{{ laborer.numAssgnts }}</VAvatar
    >
    <VLayout v-if="error">
      <VFlex class="error--text mb-2 align-center"
        >Error: {{ error }}
        <BaseButton
          small
          color="primary"
          :loading="clearing"
          :disabled="clearing"
          @click="$emit('clear')"
          >Clear</BaseButton
        ></VFlex
      >
    </VLayout>
  </div>
</template>

<script>
import VAvatar from '@vuetify/VAvatar'

export default {
  name: 'TextAssignment',
  components: { VAvatar },
  props: {
    error: {
      type: String,
      default: null,
    },
    laborer: {
      type: Object,
      required: true,
    },
    clearing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>
