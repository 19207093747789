<template>
  <div class="assets-list">
    <VCard
      :class="[
        'dispatcher-report-card text-sm-left',
        isNightShift ? 'night-shift' : 'grey lighten-2',
      ]"
    >
      <DispatcherReportCardHeader
        @selectAll="selectAllAssgnts"
        @removeAll="removeAllAssgnts"
        @selectDefaultCrew="selectDefaultCrew"
        @assignSelectedAssets="assignSelectedAssets"
        @showLocationModal="showLocationModalAction"
      >
        <RouterLink
          class="report-link-btn"
          title="View Report"
          :to="`/locations/${report.LOCATION[0].id}/dailys/${report.ID}`"
          ><VIcon
            v-if="isNightShift"
            color="#136085"
            class="night-shift-icon mr-1"
            >brightness_3</VIcon
          >{{ report.JOB_NUMBER }}
        </RouterLink>
      </DispatcherReportCardHeader>

      <WithLocation
        v-if="showLocationModal"
        :id="report.LOCATION[0].id"
        v-slot="{ isLocationLoading, location }"
      >
        <LocationModal
          :is-loading="isLocationLoading"
          :location="location"
          :view-only="true"
          :show="showLocationModal"
          @hide="showLocationModal = false"
        />
      </WithLocation>

      <div class="pa-2">
        <div class="header mb-2">
          <div class="header-detail">{{ report.LOCATION_IDENTIFIER }}</div>
          <VLayout>
            <VFlex class="header-detail"
              ><VIcon class="foreman-icon">account_circle</VIcon
              >{{ report.FOREMANS_NAME }}</VFlex
            >
            <VFlex
              v-if="reportAssignments.length"
              class="header-detail num-assets shrink"
              >{{ numAssets }}</VFlex
            >
          </VLayout>
        </div>
        <div class="assigned-assets">
          <!-- <VLayout grid-list-md justify-center column fill-height> -->
          <Draggable
            :group="{ name: 'assets' }"
            draggable=".asset-tile:not(.ghost):not(.processing)"
            :value="sortAssets(reportAssignments)"
            :sort="false"
            :class="[
              'draggable-assets column',
              { empty: reportAssignments.length === 0 },
            ]"
            @change="changeAsset"
          >
            <AssignmentTile
              v-for="assgnt of sortAssets(reportAssignments)"
              :key="`${report.ID}-${assgnt.ID}`"
              :assgnt-id="assgnt.ID"
              class="assigned mr-1"
              :label="
                assetType === 'laborer'
                  ? assgnt.LABORER_NAME
                  : assgnt.EQUIPMENT_NAME
              "
              @confirmDeleteAll="showConfirmRemoveAllModal = true"
            />

            <!-- <div
                v-if="!reportAssignments.length"
                class="no-assets-placeholder text-xs-center pa-5 my-2 display-1"
                >Drag<br />Assets<br />Here</div
              > -->
          </Draggable>
          <!-- </VLayout> -->
        </div>
        <template v-if="showAlreadyAssignedModal">
          <AssetAlreadyAssignedModal
            :show="showAlreadyAssignedModal"
            :asset="getMultiAssgnObj.asset"
            :assignments="getMultiAssgnObj.currentAssgnts"
            @confirmAssignment="confirmAssignmentAction"
            @hide="hideAssgntModalAction"
          />
        </template>
        <AssgntDeleteModal
          v-if="showConfirmRemoveAllModal"
          :show="showConfirmRemoveAllModal"
          body="Are you sure you want to remove all selected assets from their reports?"
          @hide="showConfirmRemoveAllModal = false"
          @confirmDelete="removeSelectedAssgnts"
        />
      </div>
      <!-- <DispatcherReportCardFooter :assigned-assets="reportAssignments" /> -->
    </VCard>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { LaborerTypes, AssetTypes } from '@constants/knack'
import fullstory from '@utils/fullstory'
import { mapGetters, mapActions } from 'vuex'
import AssignmentTile from '@dispatcher/AssignmentTile'
import DispatcherReportCardHeader from '@dispatcher/DispatcherReportCardHeader'
// import DispatcherReportCardFooter from '@dispatcher/DispatcherReportCardFooter'
import WithLocation from '@dataProviders/WithLocation'
import LocationModal from '@modals/LocationModal'
import AssetAlreadyAssignedModal from '@modals/AssetAlreadyAssignedModal'
import AssgntDeleteModal from '@modals/AssgntDeleteModal'

import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import _concat from 'lodash/concat'

export default {
  name: 'DispatcherReportCard',
  components: {
    Draggable,
    AssignmentTile,
    DispatcherReportCardHeader,
    // DispatcherReportCardFooter,
    WithLocation,
    LocationModal,
    AssetAlreadyAssignedModal,
    AssgntDeleteModal,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
    sort: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showLocationModal: false,
      showAlreadyAssignedModal: false,
      showConfirmRemoveAllModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'getReportAssignedAssets',
      'getAssetNameFromAssgnt',
      'getAssetIdFromAssgnt',
      'getAssetAssignments',
      'getMultiAssgnObj',
      'getSelectedAssgnts',
      'getSelectedAssets',
      'getAssgnt',
      'getAssetsForDefaultForeman',
    ]),

    assetType() {
      return this.$route.params.assetType
    }, // assetType

    sortOrder() {
      let defaultSort =
        this.assetType === 'laborer' ? 'LABORER_NAME' : 'EQUIPMENT_NAME'
      return this.sort || defaultSort
    }, // sortOrder

    reportAssignments() {
      return (
        this.getReportAssignedAssets({
          reportId: this.report.ID,
          assetType: this.assetType,
        }) || []
      )
    }, // reportAssignments

    reportAssignmentsSansForeman() {
      // Exclude the Foreman from the assets list
      return this.reportAssignments.filter((a) =>
        a[this.assetType.toUpperCase()].length
          ? a[this.assetType.toUpperCase()][0].id !== this.report.FOREMAN[0].id
          : false
      )
    }, // reportAssignmentsSansForeman

    isNightShift() {
      let hours = this.report.LOCATION_PERMIT_WORK_HOURS.split(' ')
      hours = hours
        .map((piece) => piece.trim()) // trim each piece
        .filter((piece) => piece.length > 0) // only return non-empteez
      return hours ? hours.includes('Nights') : false
    }, // isNightShift

    numAssets() {
      return `${
        this.reportAssignments.length
      }${this.assetType[0].toUpperCase()}`
    }, // numAssets
  }, // computed
  methods: {
    ...mapActions([
      'selectAssgnt',
      'deselectAssgnt',
      'deselectAllAssets',
      'selectAsset',
      'tryToAssignAsset',
      'tryToReassignAsset',
      'tryToUnassignAsset',
      'setMultiAssgnProps',
      'resetMultiAssgnProps',
    ]),

    sortAssets(assgnts) {
      let laborersByABC = _sortBy(assgnts, this.sortOrder)
      if (this.assetType === AssetTypes.EQUIPMENT) {
        return laborersByABC
      } else {
        return this.sortedLaborAssgnts(laborersByABC)
      }
    }, // sortAssets

    sortedLaborAssgnts(laborersByABC) {
      let foremenAndOperators = this.foremenAndOperators(laborersByABC)
      let basicLaborers = this.basicLaborers(laborersByABC)
      let sortedLaborAssgnts = _concat(foremenAndOperators, basicLaborers)
      return sortedLaborAssgnts
    }, // sortedLaborAssgnts

    foremenAndOperators(laborersByABC) {
      let foremanAndOperators = []
      let { assetType, report } = this
      laborersByABC.map((laborer) => {
        let isOurForeman =
          _get(laborer, `[${assetType.toUpperCase()}][0].id`) ===
          report.FOREMAN[0].id
        let isOperator = laborer.LABORERTYPE === LaborerTypes.OPERATOR
        if (isOurForeman) {
          foremanAndOperators.unshift(laborer)
        } else if (isOperator) {
          foremanAndOperators.push(laborer)
        }
      })
      return foremanAndOperators
    }, // foremenAndOperators

    basicLaborers(laborersByABC) {
      let { assetType, report } = this
      let basicLaborers = laborersByABC.filter((laborer) => {
        let isOurForeman =
          _get(laborer, `[${assetType.toUpperCase()}][0].id`) ===
          report.FOREMAN[0].id
        let isOperator = laborer.LABORERTYPE === LaborerTypes.OPERATOR
        return !(isOurForeman || isOperator)
      })
      return basicLaborers
    }, // basicLaborers

    async changeAsset(evt) {
      // if an element was added
      if (evt.added) {
        let assetType = this.assetType
        let assetId = evt.added.element.DAILY_REPORT
          ? await this.getAssetIdFromAssgnt(assetType, evt.added.element)
          : evt.added.element.ID
        let assgnts = await this.getAssetAssignments(assetType, assetId)

        let assignedHere = await this.reportAssignments
          .map((a) => this.getAssetIdFromAssgnt(assetType, a))
          .includes(assetId)
        if (assignedHere) {
          // If assigned here, reject and show "assigned here" alert
          this.$notify({
            type: 'warn',
            title: 'Cannot assign here',
            text: 'Asset already assigned to this Report',
          })
          return false
        }

        let existingAssgnt = evt.added.element.DAILY_REPORT
          ? evt.added.element
          : false

        // If dragging a new asset, launch the modal if there are ANY assgnts
        // OR
        // If dragging an existing asset, launch the modal only if there is more than 1 assgnt
        if (
          (!existingAssgnt && assgnts.length) ||
          (existingAssgnt && assgnts.length > 1)
        ) {
          // If assigned elsewhere, show "assigned elsewhere" modal
          this.showAlreadyAssignedModal = true

          this.setMultiAssgnProps({
            assetId,
            assetType,
            assgnts,
            existingAssgnt,
            targetReport: this.report,
          })

          // Short circuit the main function if already has assgnts
          return false
        }

        // If newly added item is an Assignment (as opposed to "naked" asset)
        if (evt.added.element.DAILY_REPORT) {
          let assetName = this.getAssetNameFromAssgnt(
            this.assetType,
            evt.added.element
          )
          let reassignmentPayload = {
            assetName,
            assetType: this.assetType,
            assgnt: evt.added.element,
            reportId: this.report.ID,
          }
          try {
            await this.tryToReassignAsset({
              assetType: this.assetType,
              assgnt: evt.added.element,
              reportId: this.report.ID,
            })
            this.$notify({ type: 'success', title: `${assetName} Reassigned` })
            fullstory.event(`reassignment.ok`, reassignmentPayload)
          } catch (error) {
            this.$notify({
              type: 'error',
              title: `${assetName} could not be moved to that report. Please try again.`,
            })
            fullstory.event(`reassignment.fail`, reassignmentPayload)
            throw error
          }
        }
        // If newly added item is an Unassigned Asset
        else {
          try {
            await this.tryToAssignAsset({
              assetType: this.assetType,
              assetId: evt.added.element.ID,
              reportId: this.report.ID,
            })
            this.$notify({ type: 'success', title: 'Asset Assigned' })
          } catch (error) {
            this.$notify({ type: 'error', title: 'Could not Assign asset' })
            throw error
          }
        }
      }
      // If an element was removed
      else if (evt.removed) {
      }
    }, // changeAsset

    async confirmAssignmentAction() {
      this.showAlreadyAssignedModal = false
      if (this.getMultiAssgnObj.existingAssgnt) {
        await this.tryToReassignAsset({
          assetType: this.assetType,
          assgnt: this.getMultiAssgnObj.existingAssgnt,
          reportId: this.report.ID,
        })
      } else {
        await this.tryToAssignAsset({
          assetType: this.assetType,
          assetId: this.getMultiAssgnObj.asset.ID,
          reportId: this.report.ID,
        })
      }
    }, // confirmAssignmentAction

    hideAssgntModalAction() {
      this.showAlreadyAssignedModal = false
      this.resetMultiAssgnProps()
    }, // hideAssgntModalAction

    selectAllAssgnts() {
      this.reportAssignments.forEach((assgnt) => this.selectAssgnt(assgnt.ID))
    }, // selectAllAssgnts

    selectDefaultCrew() {
      this.deselectAllAssets()
      let defaultCrew = this.getAssetsForDefaultForeman(
        this.assetType,
        this.report.FOREMAN[0].id
      )
      if (defaultCrew.length) {
        defaultCrew.forEach((asset) => this.selectAsset(asset.ID))
        this.$notify({
          title: `${this.report.FOREMAN[0].identifier}'s Crew Selected`,
          type: 'success',
        })
      } else {
        this.$notify({
          title: `No Default Crew Members Available`,
          type: 'warn',
        })
      }
    }, // selectDefaultCrew

    async assignSelectedAssets() {
      let selectedAssets = await this.getSelectedAssets
      let selectedAssgnts = await this.getSelectedAssgnts
      if (selectedAssets.length) {
        await this.$notify({ title: 'Assigning Assets' })
      }
      await selectedAssets.forEach((assetId) =>
        this.tryToAssignAsset({
          assetType: this.assetType,
          assetId,
          reportId: this.report.ID,
        })
      )
      if (selectedAssgnts.length) {
        await this.$notify({ title: 'Re-assigning Assets' })
      }
      await selectedAssgnts.forEach(async (assgntId) => {
        let assgnt = await this.getAssgnt(this.assetType, assgntId)
        return this.tryToReassignAsset({
          assetType: this.assetType,
          assgnt,
          reportId: this.report.ID,
        })
      })
    }, // assignSelectedAssets

    removeAllAssgnts() {
      this.reportAssignments.forEach((assgnt) =>
        this.tryToUnassignAsset({
          assetType: this.assetType,
          assgntId: assgnt.ID,
        })
      )
    }, // removeAllAssgnts

    removeSelectedAssgnts() {
      this.getSelectedAssgnts.forEach((assgntId) =>
        this.tryToUnassignAsset({
          assetType: this.assetType,
          assgntId,
        })
      )
    }, // removeSelectedAssgnts

    async showLocationModalAction() {
      this.showLocationModal = true
    }, // showLocationModalAction
  },
}
</script>

<style lang="scss" scoped>
.dispatcher-report-card {
  &.night-shift {
    background-color: #b5d2e0;
    .night-shift-icon {
      // position: absolute;
      // top: 2px;
      // left: 2px;
      transform: rotate(30deg);
    }
  }
}
.header-detail {
  font-weight: bold;
  .foreman-icon {
    font-size: 20px;
  }
  &.num-assets {
    width: auto;
    height: 22px;
    padding: 0 5px;
    font-size: 13px;
    line-height: 22px;
    color: white;
    text-align: center;
    background: $green;
    border-radius: 8px;
  }
}
.report-link-btn {
  display: block;
  transition: color 0.3s ease;
  &:link,
  &:visited {
    color: black;
  }
  &:hover {
    color: $blue;
  }
}
.assigned-assets {
  position: relative;
  min-height: 300px;
  max-height: 30vh;
  overflow-y: auto;
  .draggable-assets {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    &.empty::before {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 30px;
      color: $gray-mid;
      text-align: center;
      content: 'Drop Assets Here';
      border: 2px dashed $gray-mid;
    }
  }
}
</style>
