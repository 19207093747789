<script>
import { HumanFields } from '@constants/knack'
import _omit from 'lodash/omit'

import { mapActions, mapGetters, createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'WithFilters',
  props: {
    additionalFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterParams: ['filterByForeman', 'filterByReportStatus', 's'],
      areForemenLoading: false,
    }
  },
  computed: {
    ...mapGetters(['getForemen']),
    ...routeState(['query']),
    areAnyFiltersOn() {
      let queryKeys = Object.keys(this.query)
      let hasFilterMatches = queryKeys.some((k) =>
        this.filterParams.includes(k)
      )
      return hasFilterMatches || this.sortedByDate
    }, // areAnyFiltersOn
    sortedByDate() {
      return (
        this.query.sort_field &&
        this.query.sort_field === HumanFields.DAILY_REPORT.REPORT_DATE
      )
    }, // sortedByDate
    foremen() {
      return this.areForemenLoading
        ? false
        : this.getForemen.map((f) => {
            return {
              name: f.LABORER_NAME,
              value: f.LABORER_NAME,
            }
          })
    }, // foremen
  },

  watch: {
    additionalFilters: {
      async handler(val) {
        this.areForemenLoading = true
        this.$emit('awaiting foremen')
        await this.fetchForemen()
        this.areForemenLoading = false
        this.$emit('DONE awaiting foremen')
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchForemen']),
    clearFilters() {
      let sanitizedQuery = _omit(this.query, this.filterParams)
      if (this.sortedByDate) {
        delete sanitizedQuery.sort_field
        delete sanitizedQuery.sort_order
      }
      this.$router.push({
        query: sanitizedQuery,
      })
    }, // clearFilters
  },
  render() {
    return this.$scopedSlots.default({
      areAnyFiltersOn: this.areAnyFiltersOn || false,
      clearFilters: this.clearFilters,
      foremen: this.foremen || false,
      areForemenLoading: this.areForemenLoading,
    })
  },
}
</script>
