<template>
  <BaseButton
    class="modal-close-button"
    md-icon="close"
    flat
    icon
    fab
    small
    :color="color"
    text-color="black"
    :disabled="disabled"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'ModalCloseButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>

<style scoped>
.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
