<template>
  <VFlex :class="`xs12 sm${width} mb-2 location-modal-data`">
    <span class="font-weight-bold mr-2">{{ title }} :</span>
    <div :class="display">
      <slot>{{ value }}</slot>
    </div>
  </VFlex>
</template>

<script>
export default {
  name: 'LocationModalData',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    width: {
      type: [String, Number],
      default: 6,
    },
    display: {
      type: String,
      default: 'd-inline-block',
    },
  },
  data() {
    return {}
  },
}
</script>

<style>
.location-modal-data {
  display: flex;
  align-items: center;
}
</style>
