<template>
  <VCard
    :class="[
      'asset-tile my-2 py-2 px-2',
      { assigned: isAssigned },
      { selected: isSelected },
      { ghost: asset.isGhost },
      { 'elevation-0': asset.isGhost },
      { processing: asset.isProcessing },
      { 'asset-hover green accent-2 black--text': isMyAssetHovering },
      { 'elevation-6': isSelected },
      { 'multi-assigned': multiAssigned },
      `type--${laborOrEquipType}`,
    ]"
    @click="toggleSelection"
    @mouseover="hoverFn"
    @mouseleave="unhoverFn"
  >
    <VLayout row align-content-center>
      <VAvatar
        v-if="multiAssigned && !asset.isProcessing && !asset.isGhost"
        left
        size="20"
        class="num-assignments flex shrink"
        >{{ numAssignments }}</VAvatar
      >
      <BaseSpinner
        v-if="asset.isProcessing || asset.isGhost"
        size="small"
        margin="0"
        :line-fg-color="asset.isGhost ? '#DDD' : '#FFF'"
        :line-bg-color="asset.isGhost ? '#666' : '#000'"
        class="pr-2 d-inline-block"
      />
      <VFlex grow>
        <slot name="asset">no slots</slot>
      </VFlex>
      <VFlex shrink>
        <ContextualMenu
          v-if="isSelected"
          class="more-icon"
          :items="menuItems"
          :button="{ small: true, color: 'white', flat: true }"
        />
      </VFlex>
    </VLayout>
  </VCard>
</template>

<script>
import _sortBy from 'lodash/sortBy'
import { mapGetters, mapActions } from 'vuex'
import ContextualMenu from '@dispatcher/ContextualMenu'
import VAvatar from '@vuetify/VAvatar'

export default {
  name: 'AssetTile',
  components: { ContextualMenu, VAvatar },

  props: {
    asset: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    ...mapGetters([
      'getReportsResponse',
      'getIsAssetSelected',
      'getSelectedAssets',
      'getIsAssetAssigned',
      'getHoveringAssetId',
      'getAssetAssignmentKey',
    ]),
    menuItems() {
      let items = [
        {
          title: `Assign ${
            this.getSelectedAssets.length > 1 ? 'Selected Assets ' : ''
          }to...`,
        },
        ...this.assignToActions,
      ]
      return items
    }, // menuItems

    isSelected() {
      return this.getIsAssetSelected(this.asset.ID)
    }, // isSelected

    isAssigned() {
      return !!this.getIsAssetAssigned(
        this.$route.params.assetType,
        this.asset.ID
      )
    }, // isAssigned

    numAssignments() {
      return this.getIsAssetAssigned(
        this.$route.params.assetType,
        this.asset.ID
      )
    }, // numAssignments

    multiAssigned() {
      return this.numAssignments && this.numAssignments > 1
    }, // multiAssigned

    isGhost() {
      return this.asset.isGhost || this.asset.isProcessing
    }, // isGhost

    isMyAssetHovering() {
      return this.getHoveringAssetId === this.myAssetId
    },

    myAssetId() {
      // If this is a raw asset, not an assignment
      if (!this.asset.DAILY_REPORT) {
        return this.asset.ID
      }
      // If it's an assignment
      else {
        let assgntKey = this.getAssetAssignmentKey(this.$route.params.assetType)
        return this.asset[assgntKey][0].id
      }
    }, // myAssetId

    assignToReports() {
      return _sortBy(this.getReportsResponse.records, 'DAILY_REPORT_NAME')
    }, // assignToReports

    assignToActions() {
      return this.assignToReports.map((r) => {
        return {
          title: r.DAILY_REPORT_NAME,
          action: () =>
            this.getSelectedAssets.forEach((assetId) =>
              this.tryToAssignAsset({
                assetType: this.$route.params.assetType,
                assetId,
                reportId: r.ID,
              })
            ),
        }
      })
    }, // assignToActions

    laborOrEquipType() {
      return this.$route.params.assetType === 'laborer'
        ? this.asset.LABORER_TYPE.toLowerCase()
        : 'equip'
    }, // laborOrEquipType
  },
  methods: {
    ...mapActions([
      'selectAsset',
      'deselectAsset',
      'deselectAllAssets',
      'setAssetHover',
      'unsetAssetHover',
      'tryToAssignAsset',
    ]),
    toggleSelection() {
      if (this.isGhost) {
        return false
      }
      if (this.isSelected) {
        this.deselectAsset(this.asset.ID)
      } else {
        this.selectAsset(this.asset.ID)
      }
    }, // toggleSelection

    hoverFn(locationId) {
      if (!this.isAssigned) return
      if (!this.hover) {
        this.setAssetHover(this.myAssetId)
      }
      this.hover = true
    }, // hoverFn

    unhoverFn() {
      if (!this.isAssigned) return
      this.hover = false
      this.unsetAssetHover()
    }, // unhoverFn
  },
}
</script>

<style lang="scss" scoped>
.asset-tile {
  position: relative;
  font-size: 0.8em;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;

  &.sortable-ghost {
    opacity: 0.3;
  }
  &.selected {
    color: white;
    background-color: $blue;
    transform: translateY(-2px);
    &.asset-hover {
      color: $blue !important;
    }
  }
  &.ghost {
    color: white;
    cursor: not-allowed;
    background-color: $gray-mid;
    border: 2px dashed white;
    opacity: 0.8;
  }
  &.processing {
    cursor: not-allowed;
    opacity: 0.3;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 8px;
    content: '';
  }

  &.type--operator {
    &::after {
      z-index: 1000;
      background-color: $navbar-red;
    }
  }

  &.type--foreman {
    &::after {
      z-index: 1000;
      background-color: $purple;
    }
  }

  .num-assignments {
    margin-right: 4px;
    line-height: 1;
    color: white;
    background-color: $orange;
    border-radius: 100% !important;
  }
  .more-icon {
    position: absolute;
    top: 0;
    right: 0;
    .v-btn--small {
      width: 23px !important;
      height: 23px;
    }
  }
}
</style>
