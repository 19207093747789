<template>
  <div class="dispatcher-report-card-header">
    <VToolbar color="transparent" dense flat>
      <VFlex grow>
        <VToolbarTitle class="pa-0"> <slot>Title</slot></VToolbarTitle>
      </VFlex>
      <VFlex shrink>
        <ContextualMenu :items="items" />
      </VFlex>
    </VToolbar>

    <AssgntDeleteModal
      v-if="showConfirmModal"
      :show="showConfirmModal"
      body="Are you sure you want to remove all the assets from this report?"
      @hide="showConfirmModal = false"
      @confirmDelete="$emit('removeAll')"
    />
  </div>
</template>

<script>
import AssgntDeleteModal from '@modals/AssgntDeleteModal'
import ContextualMenu from '@dispatcher/ContextualMenu'
import { mapGetters } from 'vuex'
export default {
  name: 'DispatcherReportCardHeader',
  components: { AssgntDeleteModal, ContextualMenu },
  data() {
    return {
      showConfirmModal: false,
    }
  },
  computed: {
    ...mapGetters(['getAreAssgntsOrAssetsSelected']),
    items() {
      let actionItems = [
        {
          title: 'Select All',
          action: () => {
            this.$emit('selectAll')
          },
        },
        {
          title: 'Select Default Crew',
          action: () => {
            this.$emit('selectDefaultCrew')
          },
        },
      ]

      if (this.getAreAssgntsOrAssetsSelected) {
        actionItems.push({
          title: 'Add Selected Assets to this Report',
          action: () => {
            this.$emit('assignSelectedAssets')
          },
        })
      }

      actionItems = [
        ...actionItems,
        {
          title: 'Remove All',
          action: this.showConfirmModalAction,
        },
        {
          title: 'More Location Details...',
          action: () => this.$emit('showLocationModal'),
        },
      ]

      return actionItems
    }, // items
  }, // computed
  methods: {
    showConfirmModalAction() {
      this.showConfirmModal = true
    }, // showConfirmModalAction
  },
}
</script>

<style lang="scss" scoped>
.dispatcher-report-card-header {
  ::v-deep .v-toolbar__content {
    padding-right: 0;
    padding-left: 8px;
  }
}
</style>
