<script>
import dayjs from 'dayjs'

import { HumanFields } from '@constants/knack'
import { mapActions, mapGetters, createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'WithAssetsAndAssignments',
  props: {
    assetType: {
      type: String,
      required: false,
      default: '',
    },
    date: {
      type: String,
      default: () => dayjs().format('MM/DD/YYYY'),
    },
  },
  data() {
    return {
      isDataLoading: false,
    }
  },
  computed: {
    ...routeState(['query']),
    ...mapGetters(['getAvailableAssets', 'getAssignedAssets', 'getAllAssets']),
    computedAssetType() {
      return this.assetType || this.$route.params.assetType
    }, // computedAssetType
    allAssets() {
      return this.isDataLoading ? [] : this.getAllAssets(this.computedAssetType)
    }, // allAssets
    // full repsonse object including records and metadata too
    assignedAssets() {
      return this.isDataLoading
        ? []
        : this.getAssignedAssets(this.computedAssetType)
    },
    availableAssets() {
      return this.isDataLoading
        ? []
        : this.getAvailableAssets(this.computedAssetType)
    },
    filters() {
      return {
        match: 'and',
        rules: [
          {
            field: HumanFields[this.computedAssetType.toUpperCase()].STATUS,
            operator: 'is not',
            value: 'Inactive',
          },
        ],
      }
    }, // filters
  },

  watch: {
    assetType: {
      async handler(val) {
        this.isDataLoading = true
        // First load all the assets
        await this.fetchAllAssets({
          assetType: this.computedAssetType,
          rows_per_page: 1000,
          filters: this.filters,
        })
        // Then load the assigned assets for this date/type
        await this.fetchAssignedAssets({
          assetType: this.computedAssetType,
          date: this.date,
        })
        this.isDataLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchAssignedAssets', 'fetchAllAssets']),
  },
  render() {
    const { isDataLoading, allAssets, availableAssets, assignedAssets } = this
    return this.$scopedSlots.default({
      isDataLoading,
      availableAssets,
      allAssets,
      assignedAssets,
    })
  },
}
</script>
