<template>
  <div class="contextual-menu" @click.stop="stopPropagation">
    <VMenu offset-x>
      <BaseButton
        slot="activator"
        class="contextual-menu-toggle"
        v-bind="btnAttrs"
        md-icon="more_horiz"
      />
      <VList dark dense class="pa-0">
        <template v-for="(item, i) of items">
          <VSubheader v-if="item.header" :key="`${item.title}-${i}-header`">{{
            item.header
          }}</VSubheader>
          <VListTile
            :key="`${item.title}-${i}`"
            @click="item.action ? item.action() : false"
          >
            <VListTileTitle>{{ item.title }}</VListTileTitle>
          </VListTile>
          <VDivider
            v-if="i < items.length"
            :key="`${item.title}-${i}-divider`"
          ></VDivider>
        </template>
      </VList>
    </VMenu>
  </div>
</template>

<script>
import VSubheader from '@vuetify/VSubheader'
export default {
  name: 'ContextualMenu',
  components: { VSubheader },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    button: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    btnAttrs() {
      let defaults = {
        icon: true,
        color: 'transparent',
        'text-color': 'black',
      }
      return { ...defaults, ...this.button }
    }, // btnAttrs
  },
  methods: {
    stopPropagation() {
      // Stop propagation to prevent
      // parent click from being triggerd
      return true
    }, // stopPropagation
  },
}
</script>

<style lang="scss" scope>
.contextual-menu-toggle.btn-base.v-btn {
  min-width: 0 !important;
}
</style>
