<script>
import { mapActions, mapGetters } from 'vuex'
import { HumanFields } from '@constants/knack'
import _get from 'lodash/get'

export default {
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: null,
    },
    filters: {
      type: [Object, Array],
      required: false,
      default: null,
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      areReportsLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getReportsResponse',
      'getReports',
      'getProgressTotal',
      'getProgressCounter',
    ]),
    isBatchUpdating() {
      return this.getProgressCounter < this.getProgressTotal
    },
    // full repsonse object including records and metadata too
    response() {
      return this.getReportsResponse || false
    },
    fetchQuery() {
      let fetchQuery = {
        rows_per_page: this.itemsPerPage,
        filters: {
          match: 'and',
          rules: [],
        },
      }
      if (this.$route.query.s) {
        // fetchQuery.searchTerm = this.$route.query.s
        fetchQuery.filters.rules.push({
          field: HumanFields.DAILY_REPORT.DAILY_REPORT_NAME,
          operator: 'contains',
          value: this.$route.query.s,
        })
      }
      if (this.$route.query.page) {
        fetchQuery.page = Number(this.$route.query.page)
      }
      // For parsing things like "sort_field" set as props
      if (this.queryParams) {
        fetchQuery = { ...fetchQuery, ...this.queryParams }
      }
      // Let $route.query params override anything parsed
      // in the props above
      if (this.$route.query.sort_field) {
        fetchQuery.sort_field = this.$route.query.sort_field
        fetchQuery.sort_order = this.$route.query.sort_order
          ? this.$route.query.sort_order
          : 'asc'
      }
      if (this.$route.query.sort_order) {
        fetchQuery.sort_order = this.$route.query.sort_order
      }

      if (this.filters) {
        let customRules =
          typeof this.filters.rules !== 'undefined'
            ? this.filters.rules
            : this.filters
        fetchQuery.filters.rules = [...fetchQuery.filters.rules, ...customRules]
        if (this.filters.match) {
          fetchQuery.filters.match = this.filters.match
        }
      }
      return fetchQuery
    }, // fetchQuery
    reports() {
      return _get(this, 'getReports', [])
    }, //
    totalPages() {
      return _get(this, 'response.total_pages', 0)
    }, // totalPages
    page() {
      return Number(_get(this, 'response.current_page', 1))
    }, // page
    totalRecords() {
      return _get(this, 'response.total_records', 0)
    }, // totalRecords
  }, // computed
  watch: {
    $route: {
      async handler(val) {
        this.areReportsLoading = true
        await this.fetchReports(this.fetchQuery)
        this.areReportsLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchReports']),
    // TODO (ES) : Make these and clear functions DRY
    // (They have no specific relation to this data provider)
    searchReports(searchTerm) {
      let revisedQuery = { ...this.$route.query }
      // Remove pagination when doing a search
      delete revisedQuery.page
      this.$router.push({ query: { ...revisedQuery, s: searchTerm } })
    },
    clearReportsSearch() {
      let query = Object.assign({}, this.$route.query)
      delete query.s
      delete query.page
      this.$router.replace({ ...this.$router.currentRoute, query: query })
    }, // clearReportsSearch
    async refreshPage() {
      this.areReportsLoading = true
      await this.fetchReports(this.fetchQuery)
      this.areReportsLoading = false
    },
  },
  render() {
    let {
      searchReports,
      areReportsLoading,
      clearReportsSearch,
      totalPages,
      page,
      totalRecords,
      reports,
      itemsPerPage,
      isBatchUpdating,
      refreshPage,
    } = this
    return this.$scopedSlots.default({
      searchReports,
      areReportsLoading,
      clearReportsSearch,
      totalPages,
      page,
      totalRecords,
      reports,
      itemsPerPage,
      isBatchUpdating,
      refreshPage,
    })
  },
}
</script>
